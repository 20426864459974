<template>
    <div class="ClassStatistics">
        <div class="container">
            <div class="search-content">
                <a-tree-select style="width: 200px;margin-right:10px" :maxTagCount="1" :treeData="treeDataSchool" treeNodeFilterProp="title" :value="schoolVal" @change="onChangeSchool" treeCheckable :showCheckedStrategy="SHOW_PARENT" placeholder="请选择中心"/>
                <a-range-picker style="width: 250px;" v-model="selectDate" @change="_onChange"/>
                <a-button :type="dateType == 1 ? 'primary' : ''" @click="_getDate(1)">本月</a-button>
                <a-button :type="dateType == 2 ? 'primary' : ''" @click="_getDate(2)">上月</a-button>
                <a-button :type="dateType == 3 ? 'primary' : ''" @click="_getDate(3)">上上月</a-button>
                <a-button @click="_reset()">重置</a-button>
                <a-button type="primary" @click="_search">搜索</a-button>
            </div>
            <!--总课耗-->
            <div class="total" v-if="infoData">
                <div class="detail-data">
                    <ul>
                        <li>
                            <div class="item-num" v-text="infoData.Total.StudentsPerTeacher"></div>
                            <div class="item-text">老师人均会员</div>
                        </li>

                        <li>
                            <div class="item-num" v-text="infoData.Total.CoursePerStudent"></div>
                            <div class="item-text">会员月均课次</div>
                        </li>

                        <li>
                            <div class="item-num" v-text="infoData.Total.MemberAttendance+'%'"></div>
                            <div class="item-text">会员出勤率</div>
                        </li>

                        <li>
                            <div class="item-num" v-text="infoData.Total.ActiveStudent+'%'"></div>
                            <div class="item-text">活跃会员占比</div>
                        </li>

                        <li>
                            <div class="item-num" v-text="infoData.Total.SleepStudent+'%'"></div>
                            <div class="item-text">沉睡会员占比</div>
                        </li>
                        <!--<li>-->
                            <!--<div class="item-num" style="color: #009688" v-text="infoData.Total.ContractProgress+'%'"></div>-->
                            <!--<div class="item-text">合同耗课进度</div>-->
                        <!--</li>-->
                    </ul>
                </div>
                <div class="total-item">
                    <div class="icon">
                        <a-icon type="pie-chart" />
                    </div>
                    <div class="total-num" v-text="infoData.Total.TotalClassNum"></div>
                    <div class="total-text">总课次</div>
                </div>
                <div class="total-item">
                    <div class="icon">
                        <a-icon type="pie-chart" />
                    </div>
                    <div class="total-num" v-text="infoData.Total.TotalConsume"></div>
                    <div class="total-text">总课耗</div>
                </div>
            </div>
            <div class="ranking-list">
                <div style="width: 33.3%;" v-for="(item,index) in list">
                    <div class="list-item">
                        <div class="item-title">
                            <span>{{item.title}}</span>
                            <div v-show="item.list.length > 10" class="item-icon" @click="_showTable(index)">
                                <a-icon type="fullscreen" />
                            </div>
                        </div>
                        <ul>
                            <li class="li-title">
                                <div style="display: flex">
                                    <div class="code-text">{{item.arr[0]}}</div>
                                    <div class="teacher-name">{{item.arr[1]}}</div>
                                </div>
                                <div class="student-num">{{item.arr[2]}}</div>
                            </li>
                            <li class="item-data" v-for="(tab,tabIndex) in item.list" v-if="tabIndex < 10">
                                <div class="item-left">
                                    <div class="item-code">
                                        <span :class="tabIndex < 3 ? 'active' : ''">{{tabIndex + 1 }}</span>
                                    </div>
                                    <div class="teacher-msg">
                                        <img class="teacher-avatar" :src="tab.Avatar" alt="">
                                        <span class="teacher-name">{{tab.Name}}</span>
                                    </div>
                                </div>
                                <div class="item-right">{{tab.Number + (item.arr[2]=='占比' || item.arr[2]=='出勤率' || item.arr[2]=='进度' ? '%' : '' )}}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--下个月预计课耗-->
        <div class="next-container" v-if="infoData">
            <div class="content-title">下月预计课耗</div>
            <div class="content-num" v-text="infoData.Total.NextConsume"></div>
            <div class="content-title">预计课次</div>
            <div class="content-num" v-text="infoData.Total.NextClassNum"></div>
            <div class="schedule-list" v-for="(item,index) in list" v-if="index < 5">
                <div class="schedule-title">
                    <a-icon type="pie-chart"/>
                    <span>{{item.title}}</span>
                </div>
                <div class="schedule-content">
                    <slider v-if="renderComponent" :isDisabled="(item.title == '会员月均课次' || item.title == '会员出勤率') ? true : false" :min="item.sort[0]" :max="item.sort[item.sort.length-1]" v-model="item.pro"></slider>

                    <div class="schedule-text">
                        <span>{{item.sort[0]}}</span>
                        <span>{{item.sort[item.sort.length-1]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!--弹出框-->
        <a-drawer :title="tableTitle" placement="right" :closable="false" :visible="visible" :width="600" @close="_onCloseVisible()">
            <div class="ranking-list" v-if="tableIndex != -1">
                <div class="list-item" style="width: 100%;padding: 0">
                    <ul>
                        <li class="li-title">
                            <div style="display: flex">
                                <div class="code-text">{{list[tableIndex].arr[0]}}</div>
                                <div class="teacher-name">{{list[tableIndex].arr[1]}}</div>
                            </div>
                            <div class="student-num">{{list[tableIndex].arr[2]}}</div>
                        </li>
                        <li class="item-data" v-for="(tab,tabIndex) in list[tableIndex].list">
                            <div class="item-left">
                                <div class="item-code">
                                    <span>{{tabIndex + 1 }}</span>
                                </div>
                                <div class="teacher-msg">
                                    <img class="teacher-avatar" :src="tab.Avatar" alt="">
                                    <span class="teacher-name">{{tab.Name}}</span>
                                </div>
                            </div>
                            <div class="item-right">{{tab.Number + (list[tableIndex].arr[2]=='占比' || list[tableIndex].arr[2]=='出勤率' || list[tableIndex].arr[2]=='进度' ? '%' : '' )}}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import person from "../../components/SelectPersonnel/SelectPersonnel"; //选人
    import { TreeSelect } from 'ant-design-vue'
    import slider from './Slider';
    export default {
        name: "ClassStatistics",
        components: {
            person,slider
        },
        data(){
            return{
                userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),
                personObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 2, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },
                list: [
                    {
                        title: '会员月均课次',
                        arr: ['排名','中心排名','课次'],
                        list: [],
                        sort: [],
                        pro: '',
                    },
                    {
                        title: '会员出勤率',
                        arr: ['排名','中心排名','出勤率'],
                        list: [],
                        sort: [],
                        pro: '',
                    },
                    {
                        title: '老师人均会员',
                        arr: ['排名','老师排名','会员数'],
                        list: [],
                        sort: [],
                        pro: '',
                    },
                    {
                        title: '活跃会员占比',
                        arr: ['排名','中心排名','占比'],
                        list: [],
                        sort: [],
                        pro: '',
                    },
                    {
                        title: '沉睡会员占比',
                        arr: ['排名','中心排名','占比'],
                        list: [],
                        sort: [],
                        pro: '',
                    },
                    // {
                    //     title: '合同耗课进度',
                    //     arr: ['排名','中心排名','进度'],
                    //     list: [],
                    //     sort: [],
                    // },
                ],
                infoData: '',
                dateType: 2,
                selectDate: [],
                treeDataSchool: [],
                schoolVal: [],
                SHOW_PARENT: TreeSelect.SHOW_PARENT,
                tableTitle: '',
                visible: false,
                tableIndex: -1,
                renderComponent: true
            }
        },
        watch: {
            list: {
                handler:function(val,oldval){
                    //会员月均课次,会员出勤率,活跃会员数,课次单价
                    let [num1,num2,num3,num4] = [0,0,this.infoData.Total.ActiveCount,this.infoData.Total.AvgClassPrice];
                    val.map(res=>{
                        if(res.title == '会员月均课次'){
                            num1 = res.pro;
                        }else if(res.title == '会员出勤率'){
                            num2 = Number(res.pro) / 100
                        }
                    });
                    console.log(num1,num2,num3,num4);

                    this.$set(this.infoData.Total,'NextConsume',this.toThousands(parseInt(Number(num1) * Number(num2) * num3 * num4)));
                    this.$set(this.infoData.Total,'NextClassNum',this.toThousands(parseInt(Number(num1) * Number(num2) * num3)));
                },
                deep:true
            }
        },
        created(){
            let that = this;
            this._getDate(2);
            this.$axios.get(1090,
				{
					UserId: that.userInfo.uid,
				}, res => {
					// console.log(res);
					this.treeDataSchool = res.data;
            });
        },
        mounted(){
            this._search();
        },
        methods: {
            toThousands(num) {
                var result = [ ], counter = 0;
                num = (num || 0).toString().split('');
                for (var i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result.unshift(num[i]);
                    if (!(counter % 3) && i != 0) { result.unshift(','); }
                }
                return result.join('');
            },
            _showTable(index){
               this.tableTitle = this.list[index].title;
               this.tableIndex = index;
               this.visible = true;
            },
            _onCloseVisible(){
                this.visible = false;
                this.tableIndex = -1;
            },
            _reset(){
                this.schoolVal = [];
                this.dateType = '';
                this.selectDate = [];
                this._search();
            },
            _search(){
                this.$message.loading('搜索中...',0);
                this.renderComponent = false;
                this.$axios.get(8000111, {
                    SchoolId: this.schoolVal.length == 0 ? '' : this.schoolVal.join(','),
                    StartDate: this.selectDate.length == 0 ? '' : this.selectDate[0],
                    EndDate: this.selectDate.length == 0 ? '' : this.selectDate[1],
                }, res => {
                    this.$message.destroy();
                    if(res.data.code==1){

                        this.$set(this.list[0],'list',res.data.data.CoursePerStudent);
                        this.$set(this.list[0],'sort',this._sort(res.data.data.CoursePerStudent));
                        this.$set(this.list[0],'pro',res.data.data.Total.CoursePerStudent);

                        this.$set(this.list[1],'list',res.data.data.MemberAttendance);
                        this.$set(this.list[1],'sort',this._sort(res.data.data.MemberAttendance));
                        this.$set(this.list[1],'pro',res.data.data.Total.MemberAttendance);

                        this.$set(this.list[2],'list',res.data.data.StudentsPerTeacher);
                        this.$set(this.list[2],'sort',this._sort(res.data.data.StudentsPerTeacher));
                        this.$set(this.list[2],'pro',res.data.data.Total.StudentsPerTeacher);

                        this.$set(this.list[3],'list',res.data.data.ActiveStudent);
                        this.$set(this.list[3],'sort',this._sort(res.data.data.ActiveStudent));
                        this.$set(this.list[3],'pro',res.data.data.Total.ActiveStudent);

                        this.$set(this.list[4],'list',res.data.data.SleepStudent);
                        this.$set(this.list[4],'sort',this._sort(res.data.data.SleepStudent));
                        this.$set(this.list[4],'pro',res.data.data.Total.SleepStudent);
                        this.infoData = res.data.data;
                        this.renderComponent = true;
                    }
                });
            },
            _sort(list){
                let arr = [];
                list.map(res=>{
                    arr.push(Number(res.Number))
                });
                return arr.sort(function(a,b){return a - b;});
            },
            onChangeSchool (value) {
                this.schoolVal = value;
            },
            _getDate(i){
                if(i==1){
                    this.getMonthStartAndEnd(0);
                }else if(i == 2){
                    this.getMonthStartAndEnd(-1);
                }else{
                    this.getMonthStartAndEnd(-2);
                }
                this.dateType = i;
            },
            _onChange(date){
                this.selectDate = [this.__moment__(date[0]).format('YYYY-MM-DD'),this.__moment__(date[1]).format('YYYY-MM-DD')];
            },
            getMonthStartAndEnd(AddMonthCount) {
                //起止日期数组
                var startStop = new Array();
                //获取当前时间
                var currentDate = new Date();
                var month=currentDate.getMonth()+AddMonthCount;
                if(month<0){
                    var n = parseInt((-month)/12);
                    month += n*12;
                    currentDate.setFullYear(currentDate.getFullYear()-n);
                }
                currentDate = new Date(currentDate.setMonth(month));
                //获得当前月份0-11
                var currentMonth = currentDate.getMonth();
                //获得当前年份4位年
                var currentYear = currentDate.getFullYear();
                //获得上一个月的第一天
                var currentMonthFirstDay = new Date(currentYear, currentMonth,1);
                //获得上一月的最后一天
                var currentMonthLastDay = new Date(currentYear, currentMonth+1, 0);
                //添加至数组
                startStop.push(this.getDateStr3(currentMonthFirstDay));
                startStop.push(this.getDateStr3(currentMonthLastDay));
                //返回
                this.selectDate = startStop;
            },
            getDateStr3(date) {
                var year = "";
                var month = "";
                var day = "";
                var now = date;
                year = ""+now.getFullYear();
                if((now.getMonth()+1)<10){
                    month = "0"+(now.getMonth()+1);
                }else{
                    month = ""+(now.getMonth()+1);
                }
                if((now.getDate())<10){
                    day = "0"+(now.getDate());
                }else{
                    day = ""+(now.getDate());
                }
                return year+"-"+month+"-"+day;
            }
        }
    }
</script>

<style scoped lang="less">
    .ClassStatistics{
        width: 100%;
        min-height: 100%;
        display: flex;
        .container{
            flex: 1;
            padding: 15px;
            padding-right: 0;
            .search-content{
                button{
                    margin: 0 5px;
                }
            }
            .total{
                display: flex;
                margin: 15px 0;
                .detail-data{
                    flex: 1;
                    margin-right: 10px;
                    padding-bottom: 20px;
                    background-color: #fff;
                    ul{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        padding: 0 50px;
                        li{
                            width: 33.3%;
                            padding-top: 25px;
                            .item-num{
                                color: #242424;
                                font-size: 20px;
                            }
                            .item-text{
                                margin-top: 5px;
                                color: #9D9D9D;
                                font-size: 13px;
                            }
                        }
                    }
                }
                .total-item{
                    width: 230px;
                    padding: 20px;
                    margin-right: 10px;
                    background-color: #fff;
                    .total-text{
                        color: #A3A0AE;
                        font-size: 13px;
                        text-align: center;
                        margin-top: 3px;
                    }
                    .total-num{
                        margin-top: 5px;
                        color: #000;
                        font-size: 28px;
                        text-align: center;
                    }
                    .icon{
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        text-align: center;
                        border-radius: 50%;
                        background-color: #F6F8FF;
                        i{
                            color: #5375FD;
                            font-size: 16px;
                        }
                    }
                }
            }

        }
        .next-container{
            width: 350px;
            min-height: 100%;
            padding: 20px;
            background-color: #fff;
            .schedule-list{
                margin-top: 30px;
                .schedule-content{
                    .schedule-text{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 12px;
                        color: #6A6A6A;
                        font-size: 12px;
                    }
                    .schedule-line{
                        position: relative;
                        width: 100%;
                        height: 4px;
                        border-radius: 8px;
                        background-color: #D8DEFE;
                        .schedule-ok{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 20%;
                            height: 100%;
                            border-radius: 8px;
                            background-color: #2994FF;
                            .schedule-icon{
                                position: absolute;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%);
                                width: 14px;
                                height: 14px;
                                border-radius: 50%;
                                border: 2px solid #2994FF;
                                background-color: #fff;
                                cursor: pointer;
                                span{
                                    position: absolute;
                                    bottom: -25px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    color: #2994FF;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
                .schedule-title{
                    margin-bottom: 20px;
                    color: #666666;
                    font-size: 13px;
                    span{
                        margin-left: 5px;
                    }
                }
            }
            .content-title{
                margin-top: 20px;
                color: #666666;
                font-size: 20px;
            }
            .content-num{
                margin: 10px 0;
                color: #000;
                font-size: 28px;
            }
        }
    }
    .ranking-list{
        display: flex;
        //justify-content: space-between;
        flex-wrap: wrap;
        .list-item{
            padding: 15px 20px;
            margin-right: 10px;
            margin-bottom: 10px;
            background-color: #fff;
            ul{
                padding: 15px 0;
                .item-data{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 0;
                    .item-right{
                        line-height: 35px;
                        color: #242424;
                        font-size: 15px;
                    }
                    .item-left{
                        display: flex;
                        align-items: center;
                        .item-code{
                            width: 40px;
                            margin-right: 20px;
                            text-align: center;
                            span{
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                line-height: 28px;
                                border-radius: 50%;
                                background: #F6F6F6;
                                border: 1px solid #ECECEC;
                            }
                        }
                        .teacher-msg{
                            display: flex;
                            flex: 1;
                            align-items: center;
                            .teacher-avatar{
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                margin-right: 10px;
                            }
                            .teacher-name{
                                font-size: 15px;
                                color: #6D6D6D;
                            }
                        }
                    }
                }
                .li-title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    color: #808080;
                    font-size: 13px;
                    .code-text{
                        width: 40px;
                        margin-right: 20px;
                        text-align: center;
                    }
                    .teacher-name{
                        flex: 1;
                    }
                }
            }
            .item-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span{
                    color: #343434;
                    font-size: 16px;
                }
                .item-icon{
                    width: 32px;
                    height: 32px;
                    line-height: 34px;
                    border-radius: 50%;
                    cursor: pointer;
                    background-color: #EFEEF1;
                    text-align: center;
                    i{
                        color: #2994FF;
                        font-size: 18px;
                    }
                }
            }
        }
        .active{
            border-color: #FFE58F !important;
            background-color: #FFFBE6 !important;
            color: #FAAD14 !important;
        }
    }
    @media screen and (max-width: 1400px) {
        .next-container{
            width: 260px !important;
            padding: 10px !important;
        }
        .item-code{
            margin-right: 5px !important;
        }
    }
</style>
